body {
  margin: 0;
  color: #212121;
  /* font-family: "questa-sans", sans-serif; */
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

a:link {
  color: #212121;
  text-decoration: none;
}

a:visited {
  color: #212121;
  text-decoration: none;
}

a:hover {
  color: #000000;
  text-decoration: none;
}

a:active {
  color: #212121;
  text-decoration: none;
}

h1 {
  color: #212121;
  /* font-family: "questa-sans", sans-serif; */
  font-family: "Open Sans", sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 900;
  line-height: 1.2;
}

h2 {
  color: #212121;
  /* font-family: "questa-sans", sans-serif; */
  font-family: "Open Sans", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: 1.5;
  margin-top: 50px;
}

p {
  color: #212121;
  /* font-family: "questa-sans", sans-serif; */
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}
